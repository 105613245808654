import React, {useState} from 'react';

const InfoWidget = ({message}) => {
    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(true);
    };

    const onLeave = () => {
        setHover(false);
    };

    return (
        <span
            className="info-widget"
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
            style={{margin: '0 10px'}}
        >
            <i className="fa fa-info-circle" style={{cursor: "pointer"}}/>
            {hover && <span className="position-fixed p-2" style={{width: 200, color: "#fff", background: "#555", marginTop: 30, borderRadius: '0 10px 10px'}}>
                <p>{message}</p>
            </span>}
        </span>
    );
};

export default InfoWidget;
