import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {addOrderCall, parcelCreationCall} from "../../Api/ApiCalls";
import {toast} from "react-toastify";

export default function SendOrderToSendCloudModal({showModal, setShowModal}) {
const [orderId, setOrderId] = useState(null);

    return (
        <Modal
            className="modal fade"
            size={"xl"}
            show={showModal}
            onHide={setShowModal}
        >
            <div className="" role="document">
                <div className="">
                    <form>
                        <div className="modal-header">
                            <h4 className="modal-title fs-20">
                                Send to SendCloud
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setShowModal(false)}
                                data-dismiss="modal"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <i className="flaticon-cancel-12 close"></i>
                            <div className="add-contact-box">
                                <div className="add-contact-content">
                                    <div className={"row"}>
                                        <div className="form-group mb-3 col-sm-10 col-md-6">
                                            <label className="text-black font-w500">
                                                Order ID*
                                            </label>
                                            <div className="contact-name">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="orderId"
                                                    required="required"
                                                    value={orderId}
                                                    onChange={(e) => setOrderId(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-primary" onClick={() => addOrder()}>Add</a>
                            <a className="btn btn-warning" onClick={() => setShowModal(false)}>Discard</a>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );

    async function addOrder() {
        try {
            let data = {order_id: orderId}
            const result = await parcelCreationCall(data)
            setOrderId('')
            setShowModal(false);
            toast.info("Order added successfully to SendCloud!")
        } catch (err) {
            toast.error(err?.response?.data?.payload?.message[0])
        }
    }
};

