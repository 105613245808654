import React, { useEffect, useState } from 'react';
import CountUp from "react-countup";
import { fetchOrdersCall } from "../../Api/ApiCalls";

const allOptions = [
    { value: 'None', type: 'Reminders', color: 'Orange', description: 'Orders With No Reminders', key: 'NONE', filterType: 'reminderStatus' , class: 'primary', iconClass: 'primary', icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M0 192C0 103.6 71.6 32 160 32s160 71.6 160 160l0 128c0 88.4-71.6 160-160 160S0 408.4 0 320L0 192zM160 96c-53 0-96 43-96 96l0 128c0 53 43 96 96 96s96-43 96-96l0-128c0-53-43-96-96-96z"/></svg>'},
    { value: 'First', type: 'Reminders', color: 'Orange', description: 'Orders With First Reminders', key: 'FIRST_REMINDER', filterType: 'reminderStatus' , class: 'secondary', iconClass: 'secondary', icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M160 64c0-11.8-6.5-22.6-16.9-28.2s-23-5-32.8 1.6l-96 64C-.5 111.2-4.4 131 5.4 145.8s29.7 18.7 44.4 8.9L96 123.8 96 416l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0 96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0 0-352z"/></svg>'},
    { value: 'Second', type: 'Reminders', color: 'Orange', description: 'Orders With Second Reminders', key: 'SECOND_REMINDER', filterType: 'reminderStatus' , class: 'danger', iconClass: 'danger', icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M142.9 96c-21.5 0-42.2 8.5-57.4 23.8L54.6 150.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L40.2 74.5C67.5 47.3 104.4 32 142.9 32C223 32 288 97 288 177.1c0 38.5-15.3 75.4-42.5 102.6L109.3 416 288 416c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 480c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9L200.2 234.5c15.2-15.2 23.8-35.9 23.8-57.4c0-44.8-36.3-81.1-81.1-81.1z"/></svg>'},
    { value: 'Third', type: 'Reminders', color: 'Orange', description: 'Orders With Third Reminders', key: 'THIRD_REMINDER', filterType: 'reminderStatus' , class: 'warning', iconClass: 'warning', icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M0 64C0 46.3 14.3 32 32 32l240 0c13.2 0 25 8.1 29.8 20.4s1.5 26.3-8.2 35.2L162.3 208l21.7 0c75.1 0 136 60.9 136 136s-60.9 136-136 136l-78.6 0C63 480 24.2 456 5.3 418.1l-1.9-3.8c-7.9-15.8-1.5-35 14.3-42.9s35-1.5 42.9 14.3l1.9 3.8c8.1 16.3 24.8 26.5 42.9 26.5l78.6 0c39.8 0 72-32.2 72-72s-32.2-72-72-72L80 272c-13.2 0-25-8.1-29.8-20.4s-1.5-26.3 8.2-35.2L189.7 96 32 96C14.3 96 0 81.7 0 64z"/></svg>'},
    { value: 'Fourth', type: 'Reminders', color: 'Orange', description: 'Orders With Fourth Reminders', key: 'FOURTH_REMINDER', filterType: 'reminderStatus' , class: 'grey', iconClass: 'gray', icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M189 77.6c7.5-16 .7-35.1-15.3-42.6s-35.1-.7-42.6 15.3L3 322.4c-4.7 9.9-3.9 21.5 1.9 30.8S21 368 32 368l224 0 0 80c0 17.7 14.3 32 32 32s32-14.3 32-32l0-80 32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-32 0 0-144c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L82.4 304 189 77.6z"/></svg>'},
    { value: 'Done', type: 'Reminders', color: 'Orange', description: 'Done Orders', key: 'DONE', filterType: 'reminderStatus' , class: 'success', iconClass: 'success', icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>'},
    { value: 'Paid', type: 'Status', color: 'Green', description: 'Paid Orders', key: 'Paid', filterType: 'financialStatus' , class: 'dark', iconClass: 'dark', icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 32C28.7 32 0 60.7 0 96l0 32 576 0 0-32c0-35.3-28.7-64-64-64L64 32zM576 224L0 224 0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-192zM112 352l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16z"/></svg>'},
    { value: 'Pending', type: 'Status', color: 'Red', description: 'Pending Orders', key: 'Pending', filterType: 'financialStatus', class: 'info', iconClass: 'info', icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64l0 11c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437l0 11c-17.7 0-32 14.3-32 32s14.3 32 32 32l32 0 256 0 32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-11c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1l0-11c17.7 0 32-14.3 32-32s-14.3-32-32-32L320 0 64 0 32 0zM96 75l0-11 192 0 0 11c0 19-5.6 37.4-16 53L112 128c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.1 14.9L112 384z"/></svg>' }
];

export default function OrderCounterComponent({
                                                  financialStatusFilter,
                                                  reminderStatusFilter,
                                                  setFinancialStatusFilter,
                                                  setReminderStatusFilter
                                              }) {
    const initialCounts = allOptions.reduce((acc, option) => {
        acc[option.key] = 0;
        return acc;
    }, {});

    const [orderCounts, setOrderCounts] = useState(initialCounts);
    const [visibleKeys, setVisibleKeys] = useState([]);

    useEffect(() => {
        fetchAllOrders();
    }, []);

    useEffect(() => {
        let timer;
        if (visibleKeys.length < allOptions.length) {
            timer = setTimeout(() => {
                setVisibleKeys((prev) => [...prev, allOptions[visibleKeys.length].key]);
            }, 1);
        }
        return () => clearTimeout(timer);
    }, [visibleKeys]);

    function getFilters(option) {
        const apiFilters = [];
        if (option.filterType === 'reminderStatus') {
            apiFilters.push(`reminderStatus=${option.key}`);
        } else if (option.filterType === 'financialStatus') {
            apiFilters.push(`financialStatus=${option.key}`);
        }
        return apiFilters.join("&");
    }

    function fetchAllOrders() {
        allOptions.forEach((option, index) => {
            setTimeout(() => {
                fetchOrders(option);
            }, index * 300);
        });
    }

    function fetchOrders(option) {
        fetchOrdersCall(1, 1, getFilters(option))
            .then((results) => {
                setOrderCounts((prevCounts) => ({
                    ...prevCounts,
                    [option.key]: results.count
                }));
            })
            .catch((error) => {
                console.error("Error fetching orders:", error);
            });
    }

    function handleFilterClick(option) {
        if (option.filterType === 'reminderStatus') {
            setReminderStatusFilter(option.key === reminderStatusFilter ? '' : option.key);
        } else if (option.filterType === 'financialStatus') {
            setFinancialStatusFilter(option.key === financialStatusFilter ? '' : option.key);
        }
    }

    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="row">
                            {allOptions.map((option) => (
                                visibleKeys.includes(option.key) && (
                                    <div class="col-lg-3 col-sm-6 mb-3" key={option.key}
                                        onClick={() => handleFilterClick(option)}>
                                        <div class={`card-1 card-1-border-shadow-${option.class} h-100 ${((option.key === reminderStatusFilter || option.key === financialStatusFilter) ? 'active border-4 selected-card' : '')}`}>
                                            <div class="card-1-body">
                                                <div class="d-flex align-items-center mb-2">
                                                    <div class="avatar me-4">
                                                        <span class={`avatar-initial rounded bg-label-${option.iconClass}  `} 
                                                        dangerouslySetInnerHTML={{__html: option.icon}} >
                                                            
                                                        </span>
                                                    </div>
                                                    <h4 class="mb-0" style={{fontSize:'1.5rem',color: '#000'}}>
                                                        <CountUp
                                                            className="bg-white font-w900"
                                                            end={orderCounts[option.key]}/>
                                                    </h4>
                                                </div>
                                                <p class="mb-1" style={{color: '#6d6b77', fontSize: '1.15rem'}}>
                                                    {option.value} 
                                                </p>
                                                <p class="mb-0">
                                                    <span class="text-heading fw-medium me-2">{ option.description }</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function getColors(color){
        if(color === 'Green') {
            return 'text-success'
        } else if(color === 'Red') {
            return 'text-danger'
        } else {
            return 'text-secondary'
        }
    }
}
