import {apiGet, apiPost, apiPut, apiPatch} from "./Api";
import {appUrls} from "../config/Constants";
import {loginAgain} from "../utils/SharedFunctions";


export async function step1AuthCall(data) {
    return apiPost(appUrls.backendApi + "auth/login/code", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

//
export async function step2AuthCall(data) {
    return apiPost(appUrls.backendApi + "auth/login", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function fetchOrdersCall(page = 1, size = 10, filters) {
    let url =
        appUrls.backendApi + `orders?page=${page}&size=${size}`;
    if (filters?.length > 0) {
        url += `&${filters}`;
    }
    return apiGet(url)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function updateFinancialStatusCall(orderId, data) {
    return apiPut(appUrls.backendApi + `orders/${orderId}/status`, data)
    .then((result => {
        return result
    }))
    .catch((err) => {
        return err
    })
}

export async function updateOrderCall(orderId,data) {
    return apiPut(appUrls.backendApi + `orders/${orderId}`,data)
    .then((result => {
        return result
    }))
    .catch((err) => {
        return err
    })
}

export async function updateOrdersRemindersCall(data) {
    return apiPatch(appUrls.backendApi + `reminders/orders`,data)
    .then((result => {
        return result
    }))
    .catch((err) => {
        return err
    })
}

export async function sendPaymentReminderCall(orderUUID) {
    return apiPost(appUrls.backendApi + `orders/${orderUUID}/payment-reminder`, {})
    .then((result => {
        return result
    }))
    .catch((err) => {
        return err
    })
}

export async function addOrderCall(orderName) {
    return apiPost(appUrls.backendApi + `orders/new`,{
        orderNumber: orderName
    })
    .then((result => {
        return result
    }))
    .catch((err) => {
        throw err
    })
}

export async function runPaymentReminderCall() {
    return apiPost(appUrls.backendApi + `cronjob/payment-flow`,{
    })
    .catch()
}

export async function parcelCreationCall(data) {
    return apiPost(appUrls.backendApi + `parcel-creation-custom-admin`, data).catch()
}
