import React, {useState} from 'react';

export const ApiSearchField = ({searchQuery, setSearchQuery}) => {
    const [query, setQuery] = useState(searchQuery);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearchQuery(query);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="input-group mb-3 w-25">
                <input
                    className="form-control"
                    placeholder="Search"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={{width: "50%"}}
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-outline-light"
                        type="submit"
                        style={{borderRadius: "0 10px 10px 0"}}>
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </form>
    )
}
