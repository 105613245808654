import React, {useEffect, useState} from 'react'

// image
import logo from "../../../style/images/favicon.png";
import {hasAValue} from "../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import { appToastMessages, toastOptions} from "../../../config/Constants";
import {step1AuthCall, step2AuthCall} from "../../../Api/ApiCalls";
import {store} from "../../../redux/store";
import {updateUserData} from "../../../redux/actions/storeActions";
import {useSelector} from "react-redux";

export default function Login() {
    const [email, setEmail] = useState(undefined);
    const [code, setCode] = useState(undefined);
    const [showStep1, setShowStep1] = useState(true);
    const userData = useSelector( state => state.userData );
    useEffect(()=>{

    },[])

    return (
        <>
            <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="login-aside text-center d-flex justify-content-center flex-column flex-row-auto">
                    <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                        <div className="text-center mb-4">
                            <img src={logo} className={"rounded"} alt="" width={250}/>
                        </div>
                        <h3 className="mb-2">Welcome back!</h3>
                    </div>

                </div>
                <div
                    className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
                    <div className="d-flex justify-content-center h-100 align-items-center">
                        <div className="authincation-content style-2">
                            <div className="row no-gutters">
                                <div className="col-xl-12 tab-content">
                                    <div className="auth-form form-validation">
                                        <div className="form-validate">
                                            <h3 className="text-center mb-4 text-black">Sign in with your code</h3>
                                            {!showStep1 && <div className="form-group mb-3">
                                                <div>
                                                    <input type="code" className="form-control"
                                                           value={code}
                                                           onChange={(e) => setCode(e.target.value)}
                                                           placeholder="Code"
                                                    />
                                                </div>

                                            </div>}

                                            {showStep1 && <div className="text-center form-group mb-3">
                                                <input type="email" className="form-control mb-2"
                                                       name={"email"}
                                                       value={email}
                                                       onChange={(e) => setEmail(e.target.value)}
                                                       placeholder="Email address"
                                                />
                                                <button className="btn btn-primary btn-block" onClick={() => step1()}>
                                                    Request code
                                                </button>
                                            </div>}

                                            {!showStep1 && <div className="text-center form-group mb-3">
                                                <button className="btn btn-primary btn-block" onClick={() => step2()}>
                                                   Check from the code
                                                </button>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    function step1() {
        let data = {email}
        step1AuthCall(data).then(success => {
            setShowStep1(false)
            toast.success(success.message, toastOptions);
        }).catch(error => {
            toast.error(error.response.data.payload.message[0], toastOptions);
        })
    }


    function step2() {
        let data = {email, otp: code}
        if (hasAValue(code)) {
            step2AuthCall(data).then(success => {
                success = success.data
                store.dispatch(updateUserData({
                    access_token: success.accessToken,
                    refresh_token: success.refreshToken,
                }))
                toast.success(success.message, toastOptions);

                // window.location = "/dashboard"
            }).catch(error => {
                toast.error(error.response.data.error, toastOptions);
            })
        } else {
            toast.error(appToastMessages.all_fields_are_required, toastOptions);
        }
    }
}

