import React from 'react';

export const ColumnDropdownFilter = ({options, filter, setFilter}) => {
    return (
        <select
            className="form-select form-select-md mb-0 w-auto" aria-label=".form-select-md example"
            value={filter}
            onChange={e => {
                setFilter(e.target.value);
            }}
        >
            <option value="">All</option>
            {options.map((option) => (
                <option key={option.key} value={option.key}>
                    {option.value}
                </option>
            ))}
        </select>
    );
};
