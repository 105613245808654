import React, { useEffect, useState } from "react";
import { useTable, useGlobalFilter, useSortBy, useFilters, usePagination } from "react-table";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { ORDERS_COLUMNS } from "../../components/FilteringTable/Columns";
import {
	addOrderCall,
	fetchOrdersCall,
	runPaymentReminderCall,
	sendPaymentReminderCall,
	updateFinancialStatusCall,
	updateOrderCall,
	updateOrdersRemindersCall
} from "../../Api/ApiCalls";
import { ApiSearchField } from "../../components/FilteringTable/ApiSearchField";
import { ColumnDropdownFilter } from "../../components/FilteringTable/ColumnFilter";
import { appUrls } from "../../config/Constants";
import InfoWidget from "../../components/widgets/info";
import OrderCounterComponent from "../../components/widgets/OrderCounterComponent";
import SendOrderToSendCloudModal from "../../components/Modals/SendOrderToSendCloudModal";

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE || 30;

const PaymentMethod = {
	gateway: 'payment_gateway', bankTransfer: 'bank_transfer'
}

export default function Home() {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [columns, setColumns] = useState([]);
	const [totalPages, setTotalPages] = useState(undefined);
	const [currentPage, setCurrentPage] = useState(undefined);
	const [searchQuery, setSearchQuery] = useState("");
	const [financialStatusFilter, setFinancialStatusFilter] = useState(undefined);
	const [reminderStatusFilter, setReminderStatusFilter] = useState(undefined);
	const [showModal, setShowModal] = useState(false);
	const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
	const [showSendCloudModal, setShowSendCloudModal] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showJobTrigger, setShowJobTrigger] = useState(true);
	const [sendEmail, setSendEmail] = useState(false)
	const [selectedIds, setSelectedIds] = useState([]);
	const [activeDropdown, setActiveDropdown] = useState(false);
	const [sorting, setSorting] = useState({
		field: undefined, direction: undefined,
	});

	const [formData, setFormData] = useState({
		amount: 0, id: 0, orderUUID: 0, disabled: false, penaltyAmount: undefined
	});

	const [rowData, setRowData] = useState(undefined);

	const [updateStatusFormData, setUpdateStatusFormData] = useState({
		description: undefined, paymentMethod: PaymentMethod.gateway, financialStatus: 'Paid'
	});

	const [orderDataToCreate, setOrderDataToCreate] = useState({
		name: "",
	});
	const [isUpdating, setIsUpdating] = useState(false);
	const [isCreating, setIsCreating] = useState(false);

	useEffect(() => {
		setColumns(ORDERS_COLUMNS);
	}, []);

	useEffect(() => {
		fetchOrders(1);
	}, [searchQuery, reminderStatusFilter, financialStatusFilter, sorting]);

	const tableInstance = useTable({
		columns, data, initialState: {pageIndex: 0, pageSize: PAGE_SIZE},
	}, useFilters, useGlobalFilter, useSortBy, usePagination);

	const {
		getTableProps, getTableBodyProps, headerGroups, prepareRow, state, page,
	} = tableInstance;

	const {pageIndex} = state;

	function toggleCheckedRows  (row)  {
		if(row.financialStatus !== 'Paid'){
		const Ids = Object.assign([], selectedIds);
		if(Ids.includes(row.id))
		{
			let index = Ids.indexOf(row.id);
			if (index !== -1)
				Ids.splice(index, 1);
		}else
			Ids.push(row.id)
		setSelectedIds(Ids)
		}
	};
	function sendReminders()
	{
		if( selectedIds.length == 0 ) return false;
		else updateOrdersRemindersCall({"orderIds":selectedIds, "stopReminder": false})

	};
	function stopReminders()
	{
		if( selectedIds.length == 0 ) return false;
		else updateOrdersRemindersCall({"orderIds":selectedIds, "stopReminder": true})

	};
	return (<>

		<OrderCounterComponent
			financialStatusFilter={financialStatusFilter}
			reminderStatusFilter={reminderStatusFilter}
			setFinancialStatusFilter={(value)=> setFinancialStatusFilter(value)}
			setReminderStatusFilter={(value)=> setReminderStatusFilter(value)}
		/>
		<div className="card">
			<div className="card-header">
				<h4 className="card-title">Orders</h4>
				<div className="d-flex align-items-center">
					<div className="btn-dropdown" onClick={() => setActiveDropdown( !activeDropdown )}>
						<label>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 0c-17.7 0-32 14.3-32 32l0 19.2C119 66 64 130.6 64 208l0 18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416l384 0c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8l0-18.8c0-77.4-55-142-128-156.8L256 32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3l-64 0-64 0c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/></svg>
							Reminders
							<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
						</label>
						<div className={`btn-dropdown-options ${(activeDropdown ? 'active' : '')}`}>
								{showJobTrigger && <button
									onClick={() => handleRunningCronjob()}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
									Start Sending For Payment
								</button>}
								<button
									disabled={(selectedIds.length <= 0)}
									onClick={() => sendReminders()}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376l0 103.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"/></svg>
									Send Reminders
								</button>
								<button
									disabled={(selectedIds.length <= 0)}
									onClick={() => stopReminders()}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
									Stop Reminders
								</button>
						</div>
					</div>
					<button
						className="btn btn-dark"
						style={{margin: "0 10px"}}
						onClick={() => setShowSendCloudModal(true)}
					>
						Send order to SendCloud
					</button>
					<button
						className="btn btn-primary"
						onClick={() => handleCreateModal()}
					>
						+ Add Order
					</button>
				</div>
			</div>
			<div className="card-body">
				<div className="table-responsive">
					<ApiSearchField
						searchQuery={searchQuery}
						setSearchQuery={setSearchQuery}
					/>
					<table {...getTableProps()} className="table dataTable display">
						<thead>
						{headerGroups.map((headerGroup) => (<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								return (
									<th
										key={column.id}
										className={column.canSort ? 'cursor-pointer' : ''}
										onClick={column.canSort ? (e) => handleSort(e, column) : null}
									>
										<div>
											{column.render("Header")}
											<span className="ml-1">
                                            {column.canSort ? sorting.field === column.id ? (sorting.direction === 'DESC' ? (<i
												className="fa fa-arrow-down ms-2 fs-14"
												style={{opacity: "0.7"}}
											/>) : (<i
												className="fa fa-arrow-up ms-2 fs-14"
												style={{opacity: "0.7"}}
											/>)) : (<i
												className="fa fa-sort ms-2 fs-14"
												style={{opacity: "0.3"}}
											/>) : null}
                                        </span>
										</div>
										<div style={{height: "30px"}}>
											{column.canFilter ? column.id === 'reminderStatus' ?
												<ColumnDropdownFilter
													options={[{value: 'None', key: 'NONE'}, {
														value: 'First',
														key: 'FIRST_REMINDER'
													}, {
														value: 'Second',
														key: 'SECOND_REMINDER'
													}, {
														value: 'Third',
														key: 'THIRD_REMINDER'
													}, {
														value: 'Fourth',
														key: 'FOURTH_REMINDER'
													}, {value: 'Done', key: 'DONE'},]}
													filter={reminderStatusFilter}
													setFilter={setReminderStatusFilter}
												/> : column.id === 'financialStatus' ? <ColumnDropdownFilter
													options={[{
														value: 'Paid', key: 'Paid'
													}, {value: 'Pending', key: 'Pending'}]}
													filter={financialStatusFilter}
													setFilter={setFinancialStatusFilter}
												/> : null : null}
										</div>

									</th>);
							})}
						</tr>))}
						</thead>
						<tbody {...getTableBodyProps()}>
						{!loading && page.map((row) => {
							prepareRow(row);
							return (<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td
											onClick={() => { ( cell.column.id === 'id' ? toggleCheckedRows(row.original) : openUpdateModal(row) )} }
											{...cell.getCellProps()}
											className={"cursor-pointer"}
										>
											{" "}
											{cell.render("Cell", { checked : (selectedIds.includes(row.original.id)) , status : row.original.financialStatus})}{" "}
										</td>
									);
								})}
							</tr>);
						})}
						</tbody>
					</table>
					{loading &&
						<div className="d-flex justify-content-center" style={{height: 300, alignItems: 'center'}}>
							<div className="spinner-border text-secondary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>}
					{/* This is only for footer if u require */}
					<div className="d-flex justify-content-between">
              <span>
                Page{" "}
				  <strong>
                  {currentPage} of {totalPages}
                </strong>
				  {""}
              </span>
						<span className="table-index">
                Go to page :{" "}
							<input
								type="number"
								className="ml-2"
								defaultValue={pageIndex + 1}
								onChange={(e) => {
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
									fetchOrders(pageNumber);
								}}
							/>
              </span>
					</div>
					<div className="text-center mb-3">
						<div className="filter-pagination  mt-3">
							<button
								className=" previous-button"
								onClick={() => fetchOrders(1)}
								disabled={currentPage === 1}
							>
								{"<<"}
							</button>

							<button
								className="previous-button"
								onClick={() => fetchOrders(currentPage - 1)}
								disabled={currentPage === 1}
							>
								Previous
							</button>
							<button
								className="next-button"
								onClick={() => fetchOrders(currentPage + 1)}
								disabled={currentPage === totalPages}
							>
								Next
							</button>
							<button
								className=" next-button"
								onClick={() => fetchOrders(totalPages)}
								disabled={currentPage === totalPages}
							>
								{">>"}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		{/*Modal *****************/}
		<SendOrderToSendCloudModal
			showModal={showSendCloudModal} setShowModal={(value)=> setShowSendCloudModal(value)}/>

		<Modal
			className="modal fade"
			size={"xl"}
			show={showModal}
			onHide={setShowModal}
		>
			<div className="" role="document">
				<div className="">
					<form>
						<div className="modal-header">
							<h4 className="modal-title fs-20">
								{showEdit ? "Update Order" : "Add Order"}
							</h4>
							<button
								type="button"
								className="btn-close"
								onClick={() => setShowModal(false)}
								data-dismiss="modal"
							></button>
						</div>
						<div className="modal-body">
							<i className="flaticon-cancel-12 close"></i>
							<div className="add-contact-box">
								<div className="add-contact-content">
									<div className={"row"}>
										{showEdit ? (<>
											<div className="row">
												<div className="mb-3 col-sm-10 col-md-6">
													<label className="text-black font-w500">
														Order Price
													</label>
													<div className="contact-name">
														<input
															type="number"
															min={0}
															className="form-control"
															name="amount"
															required="required"
															value={formData.amount}
															onChange={handleInputChange}
														/>
													</div>
												</div>
												<div className="mb-3 col-sm-10 col-md-6">
													<label className="text-black font-w500">Hide</label>
													<div className="contact-name">
														<div className="form-check form-switch">
															<input
																className="form-check-input"
																type="checkbox"
																name="disabled"
																checked={formData.disabled}
																id="flexSwitchCheckChecked"
																onClick={handleInputChange}
															/>
															<label
																className="form-check-label"
																htmlFor="flexSwitchCheckChecked"
															>
																Checked switch will hide the order
															</label>
														</div>
													</div>
												</div>
											</div>
											{rowData.original.financialStatus !== 'Paid' &&
												<div className="row" style={{marginTop: '20px'}}>
													<div className="mb-3 col-sm-10 col-md-6">
														<div>
															<label className="text-black font-w500">Payment Link</label>
															<div className="row">
																<div className="col-9">
                                                           <span>
                                                                {`${appUrls.mollieRedirectUrl}/${rowData?.original?.uuid}`}
                                                            </span>
																</div>
																<div className="col-2">
																	<button className="btn btn-sm btn-outline-light"
																			style={{
																				marginLeft: '10px',
																				padding: '5px 10px',
																				borderRadius: '5px'
																			}} onClick={copyToClipboard}>
																		<i className="fa fa-copy fs-18"/>
																	</button>
																</div>
															</div>
														</div>
														<div style={{marginTop: 20}}>
															<input
																className="form-check-input"
																type="checkbox"
																name="removeExtra"
																checked={sendEmail}
																id="sendEmailOnSave"
																onClick={() => setSendEmail(!sendEmail)}
															/>
															<label className="form-check-label"
																   htmlFor="sendEmailOnSave"
																   style={{marginLeft: "20px"}}>
																Send Reminder Email
															</label>
															<InfoWidget
																message="When this option is selected, an email will automatically be sent to the user when saving the dialog."/>
														</div>
													</div>
													{rowData.original.reminderStatus === 'FOURTH_REMINDER' && <div className="mb-3 col-sm-10 col-md-6">
														<label className="text-black font-w500">Penalty Amount</label>
														<div className="contact-name">
															<input
																type="number"
																min={0}
																className="form-control"
																name="penaltyAmount"
																required="required"
																value={formData.penaltyAmount}
																onChange={(e) => setFormData({
																	...formData,
																	penaltyAmount: e.target.value,
																})}
															/>
														</div>
													</div>}
												</div>}
										</>) : (<div className="form-group mb-3 col-sm-10 col-md-6">
											<label className="text-black font-w500">Order Number</label>
											<div className="contact-name">
												<input
													type="text"
													className="form-control"
													name="name"
													required="required"
													value={orderDataToCreate.name}
													onChange={handleOrderInputChange}
												/>
											</div>
										</div>)}

									</div>
								</div>
							</div>
						</div>
						{showEdit && (<div
							className="modal-footer"
							style={{display: "flex", justifyContent: "space-between"}}
						>
							<div className="d-flex">
								{rowData.original.financialStatus !== 'Paid' && <div className="contact-name">
									<a
										className="btn"
										style={{background: "#68E365", color: "#fff"}}
										onClick={() => {
											setUpdateStatusFormData({
												...updateStatusFormData,
												paymentMethod: rowData.original.paymentMethod,
												description: undefined,
											});
											setShowPaymentStatusModal(true);
										}}
									>
										Mark as Paid
									</a>
								</div>}
								{!rowData?.original.deliveredAt && rowData?.original.trackingStatus.status === 'delivered' && (
									<div className="contact-name" style={{margin: "0 10px"}}>
										<a
											className="btn btn-primary"
											onClick={() => updateDeliveredAt()}
										>
											Mark as Delivered
										</a>
									</div>)}

							</div>

							<div
								style={{
									display: "flex", gap: "10px",
								}}
							>
								<a
									className="btn btn-primary"
									onClick={() => updateOrder()}
								>
									{isUpdating ? (<div
										style={{
											display: "flex", alignItems: "center", justifyContent: "center",
										}}
									>
										<div
											className="spinner-border text-white"
											role="status"
										>
											<span className="sr-only">Loading...</span>
										</div>
									</div>) : ("Save")}
								</a>
								<a
									className="btn btn-warning"
									onClick={() => setShowModal(false)}
								>
									Discard
								</a>
							</div>
						</div>)}
						{!showEdit && (<div className="modal-footer">
							<a className="btn btn-primary" onClick={() => addOrder()}>
								{isCreating ? (<div
									style={{
										display: "flex", alignItems: "center", justifyContent: "center",
									}}
								>
									<div
										className="spinner-border text-white"
										role="status"
									>
										<span className="sr-only">Loading...</span>
									</div>
								</div>) : ("Add")}
							</a>
							<a
								className="btn btn-warning"
								onClick={() => setShowModal(false)}
							>
								Discard
							</a>
						</div>)}
					</form>
				</div>
			</div>
		</Modal>

		<Modal
			className="modal fade"
			size={"xl"}
			show={showPaymentStatusModal}
			onHide={setShowPaymentStatusModal}
		>
			<div className="" role="document">
				<div className="">
					<form>
						<div className="modal-header">
							<h4 className="modal-title fs-20">
								Payment
							</h4>
							<button
								type="button"
								className="btn-close"
								onClick={() => setShowPaymentStatusModal(false)}
								data-dismiss="modal"
							></button>
						</div>
						<div className="modal-body">
							<i className="flaticon-cancel-12 close"></i>
							<div className="add-contact-box">
								<div className="add-contact-content">
									<div className={"row"}>
										<div className="form-group mb-3 col-sm-10 col-md-6">
											<h5>Payment Method</h5>
											{Object.values(PaymentMethod).map(option => (<div>
													<label key={option}>
														<input
															type="radio"
															value={option}
															checked={updateStatusFormData.paymentMethod === option}
															onChange={() => {
																setUpdateStatusFormData({
																	...updateStatusFormData, paymentMethod: option
																})
															}}
														/>
														<span style={{marginInlineStart: '10px'}}>
                                                                {option.toUpperCase().replace('_', ' ')}
                                                            </span>
													</label>
												</div>

											))}
										</div>
										<div className="form-group mb-3 col-sm-10 col-md-6">
											<label className="text-black font-w500">
												Description *
											</label>
											<div className="contact-name">
												<input
													type="text"
													className="form-control"
													name="description"
													required="required"
													value={updateStatusFormData.description}
													onChange={(e) => setUpdateStatusFormData({
														...updateStatusFormData, description: e.target.value
													})}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<a className="btn btn-primary" onClick={() => updateFinancialStatus()}>Save</a>
							<a className="btn btn-warning" onClick={() => setShowPaymentStatusModal(false)}>Discard</a>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	</>);

	function copyToClipboard(e) {
		e.preventDefault()
		navigator.clipboard.writeText(`${appUrls.mollieRedirectUrl}/${rowData?.original?.uuid}`).then(() => {
			toast.success('The link has been copied!')
		});
	}

	function handleSort(e, column) {
		const direction = !sorting.direction ? 'ASC' : sorting.direction === 'ASC' ? 'DESC' : undefined
		setSorting({
			field: direction ? column.id : undefined, direction: direction,
		})
	}

	function getFilters() {
		const apiFilters = [];
		if (searchQuery?.length) {
			apiFilters.push(`q=${encodeURIComponent(searchQuery)}`);
		}

		if (reminderStatusFilter) {
			apiFilters.push(`reminderStatus=${reminderStatusFilter}`)
		}

		if (financialStatusFilter) {
			apiFilters.push(`financialStatus=${financialStatusFilter}`)
		}

		if (sorting.direction) {
			apiFilters.push(`orderBy[field]=${sorting.field}`);
			apiFilters.push(`orderBy[direction]=${sorting.direction}`);
		}

		return apiFilters.join("&");
	}

	function fetchOrders(page) {
		setLoading(true);
		fetchOrdersCall(page, PAGE_SIZE, getFilters())
			.then((results) => {
				setData(results.items);
				setTotalPages(results.totalPages);
				setCurrentPage(results.page);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
			});
	}

	async function handleRunningCronjob() {
		runPaymentReminderCall().then(() => {
			toast.success('The job has been started successfully');
			setShowJobTrigger(false);
			setTimeout(() => {
				setShowJobTrigger(true);
			}, 2 * 60 * 1000)
		});
	}

	async function updateFinancialStatus() {
		try {
			if (!updateStatusFormData.description) {
				toast.error('Description field is required!');
				return;
			}
			const {data} = await updateFinancialStatusCall(rowData.original.id, updateStatusFormData);
			setData((prevData) => {
				return prevData.map((item) => {
					if (item.id === data.id) {
						return {
							...item, ...data
						};
					}
					return item;
				});
			});
			setIsUpdating(false);
			setShowModal(false);
			setShowPaymentStatusModal(false);
			setShowEdit(false);
			toast.success("Financial status updated successfully");
		} catch (error) {
			console.error("Error updating financial status:", error);
			toast.error("Failed to update financial status");
		}
	}

	async function updateDeliveredAt() {
		try {
			const {data} = await updateOrderCall(formData.id, {
				deliveredAt: formData.deliveredAt
			});

			setData((prevData) => {
				return prevData.map((item) => {
					if (item.id === data.id) {
						return {
							...item, deliveredAt: data.deliveredAt,
						};
					}
					return item;
				});
			});
			setIsUpdating(false);
			setShowModal(false);
			setShowEdit(false);
			toast.success("Delivered At date has been updated successfully");
		} catch (error) {
			console.error("Error updating financial status:", error);
			toast.error("Failed to update financial status");
		}
	}

	async function addOrder() {
		try {
			setIsCreating(true);
			await addOrderCall(orderDataToCreate.name)
			fetchOrders(currentPage)
			setIsCreating(false);
			setShowModal(false);
			setShowEdit(false);
			toast.info("Order Created successfully!")
		} catch (err) {
			setIsCreating(false);
			toast.error(err?.response?.data?.payload?.message[0])
		}
	}

	async function handleInputChange(e) {
		const {name, type, checked} = e.target;
		let value;

		if (type === "checkbox") {
			value = checked;
		} else if (type === "file") {
			value = e.target.files[0];
		} else {
			value = e.target.value;
		}

		setFormData((prevFormData) => ({
			...prevFormData, [name]: value,
		}));
	}

	async function handleOrderInputChange(e) {
		const {name, type, checked} = e.target;
		let value;

		if (type === "checkbox") {
			value = checked;
		} else if (type === "file") {
			value = e.target.files[0];
		} else {
			value = e.target.value;
		}

		setOrderDataToCreate((prevFormData) => ({
			...prevFormData, [name]: value,
		}));
	}

	async function openUpdateModal(row) {
		setRowData(row);
		setSendEmail(false);
		setFormData({
			amount: row.original.totalPrice,
			id: row.original.id,
			orderUUID: row.original.uuid,
			disabled: row.original.disabled,
			deliveredAt: row.original.trackingStatus.updatedAt,
			penaltyAmount: row.original.penaltyAmount,
		});
		setShowModal(true);
		setShowEdit(true);
	}

	async function handleCreateModal(row) {
		setOrderDataToCreate({
			name: "",
		});
		setShowModal(true);
		setShowEdit(false);
	}

	async function updateOrder() {
		try {
			setIsUpdating(true);
			const {data} = await updateOrderCall(formData.id, {
				totalPrice: Number(formData.amount),
				disabled: formData.disabled,
				penaltyAmount: Number(formData.penaltyAmount)
			});

			setData((prevData) => {
				return prevData.map((item) => {
					if (item.id === data.id) {
						return {
							...item, ...data,
						};
					}
					return item;
				});
			});
			toast.success("Order updated successfully");
			sendPaymentReminderCall(data.uuid).then(() => toast.success('The payment reminder email has been sent'));
			setIsUpdating(false);
			setShowModal(false);
			setShowEdit(false);
		} catch (err) {
			setIsUpdating(false);
		}
	}
}
