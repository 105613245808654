import React, {useContext, useEffect} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "../pages/internalPages/Home";
import Error400 from "../pages/ErrorPages/Error400";
import Error403 from "../pages/ErrorPages/Error403";
import Error404 from "../pages/ErrorPages/Error404";
import Error500 from "../pages/ErrorPages/Error500";
import Error503 from "../pages/ErrorPages/Error503";
import Login from "../pages/externalPages/auth/Login";
import {hasAValue} from "../utils/SharedFunctions";
import {useSelector} from "react-redux";


export function privatePages() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const userData = useSelector( state => state.userData );

    return (
        <Switch>

            {/*publicPages*/}
            {!hasAValue(userData) && <Route path='/page-login' component={Login}/>}

            {/*privatePages*/}
            {hasAValue(userData) && <Route path='/dashboard' component={Home}/>}
            {hasAValue(userData) && <Route path='/page-error-400' component={Error400}/>}
            {hasAValue(userData) && <Route path='/page-error-403' component={Error403}/>}
            {hasAValue(userData) && <Route path='/page-error-404' component={Error404}/>}
            {hasAValue(userData) && <Route path='/page-error-500' component={Error500}/>}
            {hasAValue(userData) && <Route path='/page-error-503' component={Error503}/>}

            {!hasAValue(userData) && <Redirect to="/page-login"/>}
            {hasAValue(userData) && <Redirect to="/dashboard"/>}

        </Switch>
    )
}
