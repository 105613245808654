import {formatDateTime} from "../../utils/SharedFunctions";

export const ORDERS_COLUMNS = () => [
    {
        Header: ({ value, checked, status }) => {
            return "Select";
        },
        Footer: ({ value, checked, status }) => {
            return "Select";
        },
        accessor: "id",
        Cell: ({ value, checked, status }) => {
            if (status === 'Paid') return <p style={{color: '#0c7b5e'}}>{'PAID'}</p>;
            else return (
                <input type="checkbox" checked={checked}></input>
            );
        },
    },
    {
        Header: "Order number",
        Footer: "Order number",
        accessor: "orderId",
    },
    {
        Header: "Email",
        Footer: "Email",
        accessor: "content.email",
        disableSortBy: true,
    },
    {
        Header: "Order Price",
        Footer: "Order Price",
        accessor: "totalPrice",
    },
    {
        Header: "Status",
        Footer: "Status",
        accessor: "financialStatus",
        disableSortBy: true,
    },
    {
        Header: "Reminders",
        Footer: "Reminders",
        accessor: "reminderStatus",
        disableSortBy: true,
    },
    {
        Header: "Created at",
        Footer: "Created at",
        accessor: "createdAt",
        Cell: ({value}) => {
            if (value) {
                return formatDateTime(value);
            } else {
                return "-";
            }
        },
    },
    {
        Header: "Delivered At",
        Footer: "Delivered At",
        accessor: "deliveredAt",
        Cell: ({value}) => {
            if (value) {
                return formatDateTime(value);
            } else {
                return "-";
            }
        },
    },
    {
        Header: "Payment Method",
        Footer: "Payment Method",
        accessor: "paymentMethod",
        disableSortBy: true,
        Cell: ({value}) => {
            return value.toUpperCase().replace('_', ' ')
        },
    },
    {
        Header: "Shipment Status",
        Footer: "Shipment Status",
        accessor: "trackingStatus",
        disableSortBy: true,
        Cell: ({value}) => {
            if (value.status) {
                return <p style={{color: value.status === 'delivered' ? '#0c7b5e' : ''}}>{value.status} <br/> <small>{formatDateTime(value.updatedAt)}</small></p>;
            } else {
                return "-";
            }
        },
    },

];
