import axios from "axios";
import {generateSignature, hasAValue} from "../utils/SharedFunctions";
import {store} from "../redux/store";

// const dotenv = require("dotenv");
// dotenv.config();
export const apiGet = async (route, data = {}, headersReceived = (headers) => {
}) => {
    const stateRedux = store.getState();
    let user_data = stateRedux.userData;
    let accessToken = "test"
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token
    }
    const {signature, timestamp} = generateSignature(data);
    return axios.get(route, {
        headers: {
            'Authorization': "Bearer " + accessToken,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'signature': signature,
            'timestamp': timestamp,
            'x-api-key': process.env.REACT_APP_API_KEY

        }
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            let err = JSON.parse(JSON.stringify(error));
            console.log("Error:  ", route)
            throw error;
        });
};

export const apiDelete = async (route, data = {}) => {
    const stateRedux = store.getState();
    let user_data = stateRedux.userData;
    let accessToken = "test"
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token
    }
    const {signature, timestamp} = generateSignature(data);
    return axios.post(route, data, {
        headers: {
            'Authorization': "Bearer " + accessToken,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'signature': signature,
            'timestamp': timestamp,
            'x-api-key': process.env.REACT_APP_API_KEY


        }
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log("Error:  ", route)
            throw error;
        });
};

export const apiPut = async (route, data, options = {}) => {
    const stateRedux = store.getState();
    let user_data = stateRedux.userData;
    let accessToken = "test"
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token
    }
    const {signature, timestamp} = generateSignature(data);
    return axios.put(route, data, {
        headers: {
            'Authorization': "Bearer " + accessToken,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'signature': signature,
            'timestamp': timestamp,
            'x-api-key': process.env.REACT_APP_API_KEY


        }
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log("Error:  ", route)
            throw error;
        });
};

export const apiPost = async (route, data, options = {}) => {
    const stateRedux = store.getState();
    let user_data = stateRedux.userData;
    let accessToken = "test"
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token
    }
    const {signature, timestamp} = generateSignature(data);
    return axios.post(route, data, {
        headers: {
            'Authorization': "Bearer " + accessToken,
            'Content-Type': 'application/json',
            'signature': signature,
            'timestamp': timestamp,
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            let err = JSON.parse(JSON.stringify(error));
            console.log("Error:  ", route)
            throw error;
        });
};

export const apiPatch = async (route, data, options = {}) => {
    const stateRedux = store.getState();
    let user_data = stateRedux.userData;
    let accessToken = "test"
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token
    }
    const {signature, timestamp} = generateSignature(data);
    return axios.patch(route, data, {
        headers: {
            'Authorization': "Bearer " + accessToken,
            'Content-Type': 'application/json',
            'signature': signature,
            'timestamp': timestamp,
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            let err = JSON.parse(JSON.stringify(error));
            console.log("Error:  ", route)
            throw error;
        });
};
