import {toastOptions} from "../config/Constants";
import {toast} from "react-toastify";
import {store} from "../redux/store";
import {updateUserData} from "../redux/actions/storeActions";
import CryptoJS from 'crypto-js';


export function hasAValue(value){
    if(value !== null && value !== undefined && value !== ''){
        return true
    }else{
        return false
    }
}


export  function loginAgain(error) {
    if(parseInt(error.response.status) === 401){
        store.dispatch(updateUserData(null))
        toast.warn("Please login in again", toastOptions)
        setTimeout(()=>{
            window.location = "/login"
        },3000)
    }
}

export function formatDate(isoString) {
    const date = new Date(isoString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function generateSignature(body) {
    let timestamp = new Date().getTime().toString();
    const key = timestamp + process.env.REACT_APP_Alaseel_API_KEY;
    return {
        signature: CryptoJS.HmacSHA256(JSON.stringify(body), key).toString(),
        timestamp: timestamp
    };
}

export function formatDateTime(isoDateString) {
    // Parse the ISO string into a Date object
    const date = new Date(isoDateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    // Options for formatting the date and time
    const options = {
        weekday: 'short', year: 'numeric', month: 'short',
        day: 'numeric', hour: '2-digit', minute: '2-digit'
    };

    // Format the date and time
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

